import Config from '../../config';
import {PostCall} from '../helper'
import Cookies from 'js-cookie'
import useSWR from "swr"
import {updateProfileData} from '../../redux/actions/profileActions';
import { updateCustomerData,setCustomerCartId, resetCustomer } from '../../redux/actions/customerActions';
import {updateMobileNumber,updateTokenId} from '../../redux/actions/selfserveActions';
import { isAddressValid } from '../../redux/actions/cartActions';
import store from '../../redux'
import { generateTabId } from '../../utils'
import { appLogin } from "../../api/myaccount";
import router from 'next/router'

export const URLS = {
  "SET_CSRF": Config.BASE_API_URL + "/auth/set_csrf",
  "UPDATE_SESSION": Config.BASE_API_URL + "/auth/update_session"
}

export const UpdateSession = async(data:any) => {
  let header = {...Config.DEFAULT_HEADER}
  return await PostCall(URLS.UPDATE_SESSION, data, header)
}

export const SetCSRFToken = async(url: any) => {
  let header = {...Config.DEFAULT_HEADER}
  const data = {tabId: generateTabId(), lead_id :router.query?.lead_id}
  let response = await PostCall(url, {data: data}, header, true);
  if(Config.DEBUG){
    if(response?.data.csrf_token) {
      Cookies.set('csrftoken', response.data.csrf_token)
    }
    if(response?.data.session_id) {
      Cookies.set('session_id', response.data.session_id)
    }
  }
  setAgentCookie(response?.data.user_profile)
  store.dispatch(updateProfileData(response?.data.user_profile))
  store.dispatch(setCustomerCartId(response?.data.cart?.token_id))
  const is_website_tele = response?.data.customer && response?.data.cart && response?.data.cart.channel == 'website-tele';
  if(response?.data.customer && !is_website_tele){
    store.dispatch(updateCustomerData(response?.data.customer))
    store.dispatch(updateMobileNumber(response?.data.customer?.phone))
    store.dispatch(updateTokenId(response?.data.customer?.token_id))
    setIsAuthenticate(true)
    let cust_data = response?.data?.customer;
    if(response?.data.customer && response?.data.customer.address?.length>0){
      store.dispatch(isAddressValid(true))
    }
    setCustomerCookie(cust_data)
  }else{
    setIsAuthenticate(false)
    deleteCustomerCookie()
    store.dispatch(resetCustomer())
    store.dispatch(isAddressValid(false))
  }
  triggerReloadIfCan()
  return response?.data
}

export const SetCookie = () => {
  const {data, error} = useSWR(URLS.SET_CSRF, SetCSRFToken, {
    revalidateOnFocus:false,
    revalidateOnReconnect: false
  })
  return {data, error}
}

export const setCustomerCookie = data => {
  if(data){
    if(data.address?.length>3) data.address = data.address.slice(0,3);
    data.address = data.address.map((item)=>{ delete item.additional_details; return item });
    if(data.address_info) delete data.address_info['additional_details'];
    Cookies.set('customer_personal_details', JSON.stringify(data))
  }
}

export const getCustomerCookie = () => {
  let data = Cookies.get('customer_personal_details')
  return data ? JSON.parse(data) : null
}

export const deleteCustomerCookie = () => {
  Cookies.remove('customer_personal_details')
}

// verify login
export const isAuthenticated = () => {
  let isAuthenticated = store.getState().customerReducer.is_authenticate;
  return isAuthenticated
}

export const getIsAuthenticateCookie = () => {
  return Cookies.get('is_authenticate')=="true";
}

export const setIsAuthenticate = (value) => {
  Cookies.set('is_authenticate', value)  
}

export const loginWebForAppRender = (router) => {
  // Check is it from app 
  if(!router.query?.usertoken || !router.query?.app_render){
    return false
  }
  // proceeds only if it is from app
  if(getCsrfToken()){
    if(!getIsAuthenticateCookie()){
      appLogin({
        application: "website",
        userToken: router.query?.usertoken
      }).then((res)=>{
        window.location.href = `${window.location.origin}/${router.query?.next}&app_render=true`
      }).catch((err)=>{
        router.query.sign_out = "true"
        router.push(router)
      })
    }
    else if(typeof(router.query?.next) === 'string'){
      window.location.href = `${window.location.origin}/${router.query?.next}&app_render=true`
    }
  }
  else{
    setTriggerReload(true)
  }
}

export const setTriggerReload = (value) => {
  Cookies.set('canTriggerReload', value)  
}

export const canTriggerReload = () => {
  return Cookies.get('canTriggerReload') == "true"
}

export const triggerReloadIfCan = () => {
  if(canTriggerReload()){
    setTriggerReload(false);
    window.location.reload()
  }
}

export const getCsrfToken = () => {
  return Cookies.get('csrftoken')
}

export const setAgentCookie = (data) => {
  Cookies.set('user_profile_details', JSON.stringify(data || {}))
}

export const getAgentCookie = () => {
  let data = Cookies.get('user_profile_details')
  return data ? JSON.parse(data) : {}
}

export const setPineLabsCookie = (status) => {
  Cookies.set('pine_labs_campaign', status)
}

export const getPineLabsCookie = () => {
  return Cookies.get('pine_labs_campaign');
}

export const removeOsgCampaignCookie = () => {
  Cookies.remove('osg_campaign');
}